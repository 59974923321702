/**
 * c4lClasses
 * Classes route scripts.
 * @module lib/c4lClasses
 */
'use strict';

const $ = require('jquery');

const c4lClasses = function () {
  let $body;
  let $submit;
  let $classesLinks;
  let activePath = '';

  /**
   * Cache DOM Queries so they only happen once.
   */
  function queryDom() {
    $body = $('body');
    $submit = $('#classes__submit');
    $classesLinks = $('.classes__link');
  }

  /**
   * Classes link click event handler.
   * @param  {event} e    Click event.
   * @return {boolean}    false
   */
  function handleClassesLinkClick(e) {
    const $target = $(e.target);

    // Set only the target link as selected. selected.
    $classesLinks.attr('aria-selected', false);
    $target.attr('aria-selected', true);

    // Store this path as the active path.
    activePath = $target.attr('href');

    // Enable the submit button.
    $submit.attr('disabled', false);

    // Prevent default and bubbling.
    return false;
  }

  /**
   * Submit button click event handler.
   * @return {boolean}    false
   */
  function handleClassesSubmitClick() {
    // If this is disabled or no activePath is set, do nothing.
    if ($submit.attr('disabled') || activePath === '') {
      return false;
    }

    // Navigate to the new href.
    window.location.href = activePath;

    // Prevent default and bubbling.
    return false;
  }

  /**
   * Bind DOM events.
   */
  function bindUi() {
    $body.on('click', '.classes__link', handleClassesLinkClick);
    $body.on('click', '.classes__submit', handleClassesSubmitClick);
  }

  // Public method.
  return {
    // Initialize.
    /**
     * Initialize c4lClasses functionality.
     */
    init: function () {
      queryDom();
      bindUi();
    }
  };
};

module.exports = c4lClasses();
