/**
 * c4lModernizer
 * Loads specific modernizer tests.
 * @module lib/c4lModernizer
 */

"use strict";

// Require the tests we need.
// require('browsernizr/test/css/multiplebgs');
// require('browsernizr/test/css/mediaqueries');
// require('browsernizr/test/css/animations');
// require('browsernizr/test/css/transforms');
// require('browsernizr/test/css/gradients');
require('browsernizr/test/css/flexbox');
// require('browsernizr/test/css/columns');
// require('browsernizr/test/svg');

// make sure to do this _after_ importing the tests
const Modernizr = require('browsernizr');

module.exports = Modernizr;
