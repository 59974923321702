/**
 * c4lStudentAuth
 * Classes route scripts.
 * @module lib/c4lStudentAuth
 */
'use strict';

// const $ = require('jquery');

const c4lStudentAuth = function(){

  function checkForAllThree() {
    // Array of form input IDs to look at.
    const inputFields = ['#key1', '#key2', '#key3'];

    // Don't submit if any field is empty.
    for (let i = 0; i < inputFields.length; i++) {
      if ($(inputFields[i]).val() == '') {
        return false;
      }
    }
    return true;
  }
  function submitIfReady() {

    console.log('inside');

    $.post(window.location.href, $('#studentLoginForm').serialize())
      .done(function(data) {
        window.location.href = '/games';
      })
      .fail(function(data) {
        console.log('fail data', data);
        $("#animatedModal-content").html(data.responseJSON.message);
        $("#studentLoginError").trigger('click');
      });

    return false;
  }

  function clearForm() {
    $('.thumbnail').removeClass('selected');
    $('.password_input').val('');
    $('#student_login_submit').attr('disabled', 'disabled');
    $('#studentLoginForm').focus();
  }

  // Public method.
  return {
    /**
     * Initialize c4lStudentAuth functionality.
     */
    init: function () {
      //
      // Add a hidden form
      //
      let input = $('<input>')
          .attr('type', 'hidden')
          .attr('name', 'json')
          .val(true);
      $('#studentLoginForm').append($(input));
      $('#studentLoginError').animatedModal({
        animatedIn: 'lightSpeedIn',
        animatedOut: 'fadeOut',
        animationDuration: '.6s',
        color: '#00859b',
        afterOpen: function () {
          clearForm();
          setTimeout(function () {
            $('#animatedModal-close').trigger('click');
          }, 1000);
        }
      });

      //
      // Add clicking functionality
      //
      $('.image_picker_selector').each(function (index, elem) {
        const $container = $(elem);
        const inputName = $container.attr('data-input');
        $container.find('.thumbnail').each(function (index, elem) {
          $(elem).click(function () {
            $container.find('.thumbnail').removeClass('selected');
            $(this).addClass('selected');
            $('#' + inputName).val(index + 1);
            if(checkForAllThree()) {
              $('#student_login_submit').removeAttr('disabled');
            }
          });
        });
      });

      $('#student_login_submit').click(function () {
        submitIfReady();
      });

    }
  };
};

module.exports = c4lStudentAuth();
