"use strict";

require('./lib/c4lModernizr');
const c4lClasses = require('./lib/c4lClasses');
const c4lStudentAuth = require('./lib/c4lStudentAuth');

(function($){
  $( document ).ready(function() {
    c4lStudentAuth.init();
    c4lClasses.init();
  });
})(jQuery);
